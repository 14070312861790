import React from 'react';
import type { HeadFC } from 'gatsby';

import {
  Seo,
  Layout,
  PostBox,
} from '../components';

const WritingPage = () => (
  <Layout>
    <PostBox title="Writing">
      <p>Coming soon!</p>
    </PostBox>
  </Layout>
);

export default WritingPage;

export const Head: HeadFC = () => (<Seo title="Writing" description="Stories and writings of Alese Lea Taylor" />);
